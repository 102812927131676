import type { Attachment } from "../../channels/inbox-channel.types";
import { noNullQuerySelector } from "../utils/dom";
import { getAttachment } from "./renderers/attachment";

export const removeQuotedMessageInput = () => {
  const quotedMessage = noNullQuerySelector<HTMLDivElement>("#quoted-message");
  const quotedMessageText = noNullQuerySelector<HTMLParagraphElement>(
    "#quoted-message-text",
  );
  const quotedMessageSender = noNullQuerySelector<HTMLParagraphElement>(
    "#quoted-message-sender",
  );
  const quotedMessageIdInput =
    noNullQuerySelector<HTMLInputElement>("#quoted-message-id");

  // Hide the quoted message container
  quotedMessage.classList.add("d-none");
  // Reset the quoted_message_id in the hidden field to prevent accidental quoting
  quotedMessageIdInput.value = "";

  // Clear the quoted message text and sender
  quotedMessageText.innerHTML = "";
  quotedMessageSender.textContent = "";
};

export const renderQuotedMessageContent = (
  element: HTMLElement,
  text: string,
  attachments: Attachment[],
) => {
  if (attachments.length > 0) {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add("d-flex", "flex-wrap", "gap-1");
    attachments.forEach((attachment) => {
      containerDiv.appendChild(getAttachment(attachment, { id: false }));
    });
    element.appendChild(containerDiv);
  }
  const textNode = document.createElement("span");
  textNode.innerHTML = text;
  element.appendChild(textNode);
};

export const addReplyListeners = () => {
  const replyButtons = document.querySelectorAll<HTMLAnchorElement>(
    ".reply-message-link:not(.listener-added)",
  );
  const messageInput = noNullQuerySelector<HTMLTextAreaElement>("#message");
  const quotedMessage = noNullQuerySelector<HTMLDivElement>("#quoted-message");
  const quotedMessageText = noNullQuerySelector<HTMLDivElement>(
    "#quoted-message-text",
  );
  const quotedMessageSender = noNullQuerySelector<HTMLParagraphElement>(
    "#quoted-message-sender",
  );
  const quotedMessageIdInput =
    noNullQuerySelector<HTMLInputElement>("#quoted-message-id");
  const cancelQuotedMessage = noNullQuerySelector<HTMLButtonElement>(
    "#cancel-quoted-message",
  );

  replyButtons.forEach((button) => {
    const messageId = button.getAttribute("data-message-id");
    const messageText = button.getAttribute("data-message-text");
    const messageSender = button.getAttribute("data-message-sender");
    const attachments = JSON.parse(
      button.getAttribute("data-attachments") || "[]",
    ) as Attachment[];

    // If any of the attributes are missing, skip this button because we can't reply to the message
    if (messageId === null || messageText === null || messageSender === null)
      return;

    button.addEventListener("click", () => {
      // Set the value in the hidden input field so the backend can make the proper mapping
      // once the form is submitted
      quotedMessageIdInput.value = messageId;

      // Focus on the message input field
      messageInput.focus();

      quotedMessageText.innerHTML = "";
      renderQuotedMessageContent(quotedMessageText, messageText, attachments);

      quotedMessageSender.innerHTML = messageSender;
      quotedMessage.classList.remove("d-none");
    });
  });

  cancelQuotedMessage.addEventListener("click", removeQuotedMessageInput);
};
