import type { Message } from "../../channels/inbox-channel.types";
import { noNullQuerySelector } from "../utils/dom";

/*
  Update the latest message being shown by submitting the users list form

  @param message - The message to update
  @param isSender - Whether the current user sent the message
*/
export const updateLatestMessage = (message: Message, isSender: boolean) => {
  const listItem = noNullQuerySelector(
    `#chat-list-item-${
      message.chat_group_id
        ? `group-${message.chat_group_id}`
        : `user-${isSender ? message.recipient.id : message.sender.id}`
    }`,
  );

  const latestMessageElement = noNullQuerySelector(".last-message", listItem);

  const attachment = message.attachments.slice(-1)[0];
  latestMessageElement.innerHTML = attachment
    ? getAttachmentDisplay(attachment)
    : message.text;
};

export const getAttachmentDisplay = (attachment: Message["attachments"][0]) => {
  const extension = attachment.filename.split(".").pop() as string;

  return `${
    attachment.filetype.startsWith("image") ||
    ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp"].includes(extension)
      ? `<i class="bi bi-image-fill" aria-label="Image"></i> `
      : attachment.filetype.startsWith("video") ||
          ["mp4", "webm", "ogg"].includes(extension)
        ? `<i class="bi bi-play-fill" aria-label="Video"></i> `
        : `<i class="bi bi-file-text-fill" aria-label="File"></i> `
  } ${attachment.filename}`;
};
