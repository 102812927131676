import markAsRead from "../mark-as-read";

const decrementCounter = (element: HTMLElement) => {
  const newCount = Math.max(
    parseInt(element.getAttribute("data-count") || "0") - 1,
    0,
  );
  element.setAttribute("data-count", newCount.toString());
  // Hide indicator if now unread messages left
  if (newCount === 0) {
    element.classList.add("d-none");
  } else {
    element.classList.remove("d-none");
    if (newCount > 9) {
      element.innerText = "9+";
    } else {
      element.innerText = newCount.toString();
    }
  }
};

// Decrement unread message counters
const decrementCounters = (chatId: string, type: string) => {
  document
    .querySelectorAll<HTMLElement>(
      `.unread-message-count[data-${type}-id="${chatId}"], .unread-message-count[data-type="header"]`,
    )
    .forEach((element) => {
      decrementCounter(element);
    });
};

const unreadCallback: IntersectionObserverCallback = async (entries, _) => {
  entries.forEach(async (entry) => {
    // Check if message is in viewport
    if (entry.isIntersecting) {
      const element = entry.target as HTMLElement;
      const id = element.dataset.id as string;
      const chatId = element.dataset.chatId as string;
      const type = element.dataset.type as string;

      markAsRead(element);
      decrementCounters(chatId, type);
      document.dispatchEvent(new CustomEvent("mark-as-read", { detail: id }));
    }
  });
};

const unreadObserver = new IntersectionObserver(unreadCallback, {
  root: null,
  rootMargin: "0px",
  threshold: 0.25,
});

// Intersection observer API code to detect read messages
const observeUnread = () => {
  unreadObserver.disconnect(); // Remove previous if there are any

  document
    .querySelectorAll(".received-message.unread:not([data-observed])")
    .forEach((element) => {
      unreadObserver.observe(element);

      element.setAttribute("data-observed", "true");
    });
};

export default observeUnread;
