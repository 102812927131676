/**
 * @description: Debounce function to prevent multiple calls to the same function within a given time frame
 * @param {Function} func - Function to be debounced
 * @param {number} timeout - Time in milliseconds to wait before calling the function again
 * @returns {Function} - Debounced function
 */
export const debounce = (func: Function, timeout = 300) => {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
