const ANIMATION_OPTIONS: KeyframeAnimationOptions = {
  duration: 100,
  iterations: 1,
};

export const animateHide = async (element: HTMLElement) => {
  const hideAnimation = element.animate(
    [{ transform: "scaleY(1)" }, { transform: "scaleY(0)" }],
    ANIMATION_OPTIONS,
  );
  await hideAnimation.finished;
  element.classList.add("d-none");
};

export const animateShow = async (element: HTMLElement) => {
  const showAnimation = element.animate(
    [{ transform: "scaleY(0)" }, { transform: "scaleY(1)" }],
    ANIMATION_OPTIONS,
  );
  await showAnimation.finished;
  element.classList.remove("d-none");
};
