import type { Attachment } from "../../../channels/inbox-channel.types";
import { humanFileType } from "../../utils/file";

type RenderOptions = {
  id: boolean;
};

const getDownloadLink = (attachment: Attachment) => {
  const downloadLink = document.createElement("a");
  downloadLink.target = "_blank";
  downloadLink.download = attachment.filename;
  downloadLink.className =
    "text-secondary-emphasis text-center rounded-bottom-3 bg-secondary-subtle border py-1 border-2 border-top-0 border-secondary";
  downloadLink.href = attachment.attachment;

  const downloadIcon = document.createElement("i");
  downloadIcon.className = "bi bi-download fs-4";
  downloadIcon.setAttribute("aria-label", "Download Video");
  downloadLink.appendChild(downloadIcon);

  return downloadLink;
};

const getAttachmentImage = (attachment: Attachment, options: RenderOptions) => {
  const imageContainer = document.createElement("div");
  if (options.id) imageContainer.id = `attachment-${attachment.id}`;
  imageContainer.className =
    "attachment-container d-flex flex-column position-relative my-2";

  const image = document.createElement("img");
  image.src = attachment.attachment;
  image.title = "Open Image";
  image.width = 320;
  image.height = 320;
  image.alt = attachment.filename;
  image.loading = "lazy";
  image.className =
    "attachment rounded-top-3 popup-image border border-2 border-secondary border-bottom-0";
  imageContainer.appendChild(image);
  imageContainer.appendChild(getDownloadLink(attachment));

  return imageContainer;
};

const getAttachmentVideo = (attachment: Attachment, options: RenderOptions) => {
  const videoContainer = document.createElement("div");
  if (options.id) videoContainer.id = `attachment-${attachment.id}`;
  videoContainer.className =
    "attachment-container d-flex flex-column position-relative my-2";

  const video = document.createElement("video");
  video.src = attachment.attachment;
  video.title = "Open Video";
  video.dataset.title = attachment.filename;
  video.controls = true;
  video.preload = "metadata";
  video.width = 320;
  video.className =
    "attachment rounded-top-3 border border-2 border-secondary border-bottom-0";
  videoContainer.appendChild(video);
  videoContainer.appendChild(getDownloadLink(attachment));

  return videoContainer;
};

const getAttachmentFile = (attachment: Attachment, options: RenderOptions) => {
  const fileContainer = document.createElement("a");
  if (options.id) fileContainer.id = `attachment-${attachment.id}`;
  fileContainer.className =
    "rounded-3 my-2 d-flex align-items-start position-relative bg-secondary-subtle text-secondary-emphasis px-2 py-3 gap-2 border border-2 border-secondary";
  fileContainer.target = "_blank";
  fileContainer.download = attachment.filename;
  fileContainer.href = attachment.attachment;

  const fileIcon = document.createElement("i");
  fileIcon.className = "bi bi-file-text fs-2";
  fileIcon.setAttribute("aria-hidden", "true");
  fileContainer.appendChild(fileIcon);

  const fileInfo = document.createElement("div");
  fileInfo.className =
    "d-flex flex-column flex-fill align-items-start overflow-hidden";

  const fileName = document.createElement("span");
  fileName.className = "lh-base w-100 overflow-hidden text-truncate";
  fileName.textContent = attachment.filename;
  fileInfo.appendChild(fileName);

  const fileType = document.createElement("span");
  fileType.className = "lh-base fs-12 w-100 overflow-hidden text-truncate";
  fileType.style.opacity = "0.75";
  fileType.textContent = humanFileType(attachment.filetype);
  fileInfo.appendChild(fileType);

  fileContainer.appendChild(fileInfo);

  const downloadIcon = document.createElement("i");
  downloadIcon.className = "bi bi-download fs-5";
  downloadIcon.setAttribute("aria-label", "Download File");
  fileContainer.appendChild(downloadIcon);

  return fileContainer;
};

export const getAttachment = (
  attachment: Attachment,
  options: RenderOptions = {
    id: true,
  },
) =>
  attachment.filetype.startsWith("image")
    ? getAttachmentImage(attachment, options)
    : attachment.filetype.startsWith("video")
      ? getAttachmentVideo(attachment, options)
      : getAttachmentFile(attachment, options);
