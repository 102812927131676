import { MAXIMUM_FILE_UPLOADS } from "../constants";
import { showMessage } from "./alert";

export const getUniqueFileId = (file: File) =>
  `${file.lastModified}-${file.name}-${file.size}`;

export const isAboveMaxFileSize = (file: File) => {
  const size = file.size / 1024 / 1024 / 1024; // In Gib
  return size >= 2;
};

export const showMaxFileSizeError = () =>
  showMessage(
    `
    File size greater than 2GB is not allowed.
    If you wish to send a larger file,
    please upload it to Google Drive and share the link here.
    If you don't know how to do that please
    <a
      href='https://youtu.be/_jeNEWDLu7o'
      target='_blank'
      rel="noopener noreferrer"
    >
      watch this video
    </a>
    to learn.
  `,
    "danger",
    true,
  );

export const showMaxAttachmentsError = () =>
  showMessage(
    `
    You can only upload ${MAXIMUM_FILE_UPLOADS} files at a time.
    If you wish to send more files, you can send them in another message.
  `,
    "danger",
    true,
  );

/** cSpell: disable */
export const MIME_TO_HUMAN = {
  "application/vnd.lotus-1-2-3": "123",
  "application/vnd.1000minds.decision-model+xml": "1km",
  "text/vnd.in3d.3dml": "3dml",
  "image/x-3ds": "3ds",
  "video/3gpp2": "3g2",
  "video/3gpp": "3gp",
  "model/3mf": "3mf",
  "application/x-7z-compressed": "7z",
  "application/x-authorware-bin": "aab",
  "audio/x-aac": "aac",
  "application/x-authorware-map": "aam",
  "application/x-authorware-seg": "aas",
  "application/x-abiword": "abw",
  "application/vnd.nokia.n-gage.ac+xml": "ac",
  "application/vnd.americandynamics.acc": "acc",
  "application/x-ace-compressed": "ace",
  "application/vnd.acucobol": "acu",
  "audio/adpcm": "adp",
  "audio/aac": "adts",
  "application/vnd.audiograph": "aep",
  "application/vnd.ibm.modcap": "afp",
  "application/vnd.age": "age",
  "application/vnd.ahead.space": "ahead",
  "audio/x-aiff": "aiff",
  "application/vnd.adobe.air-application-installer-package+zip": "air",
  "application/vnd.dvb.ait": "ait",
  "application/vnd.amiga.ami": "ami",
  "application/automationml-aml+xml": "aml",
  "application/automationml-amlx+zip": "amlx",
  "audio/amr": "amr",
  "application/vnd.android.package-archive": "apk",
  "image/apng": "apng",
  "application/appinstaller": "appinstaller",
  "application/x-ms-application": "application",
  "application/appx": "appx",
  "application/appxbundle": "appxbundle",
  "application/vnd.lotus-approach": "apr",
  "application/x-freearc": "arc",
  "application/x-arj": "arj",
  "video/x-ms-asf": "asf",
  "text/x-asm": "asm",
  "application/vnd.accpac.simply.aso": "aso",
  "application/vnd.acucorp": "atc",
  "application/atom+xml": "atom",
  "application/atomcat+xml": "atomcat",
  "application/atomdeleted+xml": "atomdeleted",
  "application/atomsvc+xml": "atomsvc",
  "application/vnd.antix.game-component": "atx",
  "audio/basic": "au",
  "image/avci": "avci",
  "image/avcs": "avcs",
  "video/x-msvideo": "avi",
  "image/avif": "avif",
  "application/applixware": "aw",
  "application/vnd.airzip.filesecure.azf": "azf",
  "application/vnd.airzip.filesecure.azs": "azs",
  "image/vnd.airzip.accelerator.azv": "azv",
  "application/vnd.amazon.ebook": "azw",
  "image/vnd.pco.b16": "b16",
  "application/x-bcpio": "bcpio",
  "application/x-font-bdf": "bdf",
  "application/vnd.syncml.dm+wbxml": "bdm",
  "application/x-bdoc": "bdoc",
  "application/vnd.realvnc.bed": "bed",
  "application/vnd.fujitsu.oasysprs": "bh2",
  "application/x-blorb": "blorb",
  "application/vnd.bmi": "bmi",
  "application/vnd.balsamiq.bmml+xml": "bmml",
  "image/x-ms-bmp": "bmp",
  "application/vnd.previewsystems.box": "box",
  "model/vnd.valve.source.compiled-map": "bsp",
  "image/prs.btif": "btif",
  "application/octet-stream": "buffer",
  "application/x-bzip": "bz",
  "application/x-bzip2": "bz2",
  "application/vnd.cluetrust.cartomobile-config": "c11amc",
  "application/vnd.cluetrust.cartomobile-config-pkg": "c11amz",
  "application/vnd.clonk.c4group": "c4d",
  "application/vnd.ms-cab-compressed": "cab",
  "audio/x-caf": "caf",
  "application/vnd.curl.car": "car",
  "application/vnd.ms-pki.seccat": "cat",
  "application/x-cbr": "cbr",
  "application/x-cocoa": "cco",
  "application/ccxml+xml": "ccxml",
  "application/vnd.contact.cmsg": "cdbcmsg",
  "application/x-netcdf": "cdf",
  "application/cdfx+xml": "cdfx",
  "application/vnd.mediastation.cdkey": "cdkey",
  "application/cdmi-capability": "cdmia",
  "application/cdmi-container": "cdmic",
  "application/cdmi-domain": "cdmid",
  "application/cdmi-object": "cdmio",
  "application/cdmi-queue": "cdmiq",
  "chemical/x-cdx": "cdx",
  "application/vnd.chemdraw+xml": "cdxml",
  "application/vnd.cinderella": "cdy",
  "application/pkix-cert": "cer",
  "application/x-cfs-compressed": "cfs",
  "image/cgm": "cgm",
  "application/x-chat": "chat",
  "application/vnd.ms-htmlhelp": "chm",
  "application/vnd.kde.kchart": "chrt",
  "chemical/x-cif": "cif",
  "application/vnd.anser-web-certificate-issue-initiation": "cii",
  "application/vnd.ms-artgalry": "cil",
  "application/node": "cjs",
  "application/vnd.claymore": "cla",
  "application/java-vm": "class",
  "model/vnd.cld": "cld",
  "application/vnd.crick.clicker.keyboard": "clkk",
  "application/vnd.crick.clicker.palette": "clkp",
  "application/vnd.crick.clicker.template": "clkt",
  "application/vnd.crick.clicker.wordbank": "clkw",
  "application/vnd.crick.clicker": "clkx",
  "application/x-msclip": "clp",
  "application/vnd.cosmocaller": "cmc",
  "chemical/x-cmdf": "cmdf",
  "chemical/x-cml": "cml",
  "application/vnd.yellowriver-custom-menu": "cmp",
  "image/x-cmx": "cmx",
  "application/vnd.rim.cod": "cod",
  "text/coffeescript": "coffee",
  "application/x-cpio": "cpio",
  "application/cpl+xml": "cpl",
  "text/x-c": "cpp",
  "application/mac-compactpro": "cpt",
  "application/x-mscardfile": "crd",
  "application/pkix-crl": "crl",
  "application/x-x509-ca-cert": "crt",
  "application/x-chrome-extension": "crx",
  "application/vnd.rig.cryptonote": "cryptonote",
  "application/x-csh": "csh",
  "application/vnd.citationstyles.style+xml": "csl",
  "chemical/x-csml": "csml",
  "application/vnd.commonspace": "csp",
  "text/css": "css",
  "text/csv": "csv",
  "application/cu-seeme": "cu",
  "text/vnd.curl": "curl",
  "application/cwl": "cwl",
  "application/prs.cww": "cww",
  "model/vnd.collada+xml": "dae",
  "application/vnd.mobius.daf": "daf",
  "application/vnd.dart": "dart",
  "application/davmount+xml": "davmount",
  "application/vnd.dbf": "dbf",
  "application/docbook+xml": "dbk",
  "text/vnd.curl.dcurl": "dcurl",
  "application/vnd.oma.dd2+xml": "dd2",
  "application/vnd.fujixerox.ddd": "ddd",
  "application/vnd.syncml.dmddf+xml": "ddf",
  "image/vnd.ms-dds": "dds",
  "application/x-debian-package": "deb",
  "application/vnd.dreamfactory": "dfac",
  "application/x-dgc-compressed": "dgc",
  "image/bmp": "dib",
  "application/x-director": "dir",
  "application/vnd.mobius.dis": "dis",
  "message/disposition-notification": "disposition-notification",
  "image/vnd.djvu": "djvu",
  "application/x-apple-diskimage": "dmg",
  "application/vnd.tcpdump.pcap": "dmp",
  "application/vnd.dna": "dna",
  "application/msword": "doc",
  "application/vnd.ms-word.document.macroenabled.12": "docm",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
  "application/vnd.ms-word.template.macroenabled.12": "dotm",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
    "dotx",
  "application/vnd.osgi.dp": "dp",
  "application/vnd.dpgraph": "dpg",
  "image/dpx": "dpx",
  "audio/vnd.dra": "dra",
  "image/dicom-rle": "drle",
  "text/prs.lines.tag": "dsc",
  "application/dssc+der": "dssc",
  "application/x-dtbook+xml": "dtb",
  "application/xml-dtd": "dtd",
  "audio/vnd.dts": "dts",
  "audio/vnd.dts.hd": "dtshd",
  "video/vnd.dvb.file": "dvb",
  "application/x-dvi": "dvi",
  "application/atsc-dwd+xml": "dwd",
  "model/vnd.dwf": "dwf",
  "image/vnd.dwg": "dwg",
  "image/vnd.dxf": "dxf",
  "application/vnd.spotfire.dxp": "dxp",
  "audio/vnd.nuera.ecelp4800": "ecelp4800",
  "audio/vnd.nuera.ecelp7470": "ecelp7470",
  "audio/vnd.nuera.ecelp9600": "ecelp9600",
  "application/ecmascript": "ecma",
  "application/vnd.novadigm.edm": "edm",
  "application/vnd.novadigm.edx": "edx",
  "application/vnd.picsel": "efif",
  "application/vnd.pg.osasli": "ei6",
  "image/emf": "emf",
  "application/emma+xml": "emma",
  "application/emotionml+xml": "emotionml",
  "application/x-msmetafile": "emz",
  "audio/vnd.digital-winds": "eol",
  "application/vnd.ms-fontobject": "eot",
  "application/epub+zip": "epub",
  "application/vnd.eszigno3+xml": "es3",
  "application/vnd.osgi.subsystem": "esa",
  "application/vnd.epson.esf": "esf",
  "text/x-setext": "etx",
  "application/x-eva": "eva",
  "application/x-envoy": "evy",
  "application/x-msdownload": "exe",
  "application/exi": "exi",
  "application/express": "exp",
  "image/aces": "exr",
  "application/vnd.novadigm.ext": "ext",
  "application/andrew-inset": "ez",
  "application/vnd.ezpix-album": "ez2",
  "application/vnd.ezpix-package": "ez3",
  "video/x-f4v": "f4v",
  "image/vnd.fastbidsheet": "fbs",
  "application/vnd.adobe.formscentral.fcdt": "fcdt",
  "application/vnd.isac.fcs": "fcs",
  "application/vnd.fdf": "fdf",
  "application/fdt+xml": "fdt",
  "application/vnd.denovo.fcselayout-link": "fe_launch",
  "application/vnd.fujitsu.oasysgp": "fg5",
  "image/x-freehand": "fh",
  "application/x-xfig": "fig",
  "image/fits": "fits",
  "audio/x-flac": "flac",
  "video/x-fli": "fli",
  "application/vnd.micrografx.flo": "flo",
  "video/x-flv": "flv",
  "application/vnd.kde.kivio": "flw",
  "text/vnd.fmi.flexstor": "flx",
  "text/vnd.fly": "fly",
  "application/vnd.frogans.fnc": "fnc",
  "application/vnd.software602.filler.form+xml": "fo",
  "text/x-fortran": "for",
  "image/vnd.fpx": "fpx",
  "application/vnd.framemaker": "frame",
  "application/vnd.fsc.weblaunch": "fsc",
  "image/vnd.fst": "fst",
  "application/vnd.fluxtime.clip": "ftc",
  "application/vnd.anser-web-funds-transfer-initiation": "fti",
  "video/vnd.fvt": "fvt",
  "application/vnd.adobe.fxp": "fxp",
  "application/vnd.fuzzysheet": "fzs",
  "application/vnd.geoplan": "g2w",
  "image/g3fax": "g3",
  "application/vnd.geospace": "g3w",
  "application/vnd.groove-account": "gac",
  "application/x-tads": "gam",
  "application/rpki-ghostbusters": "gbr",
  "application/x-gca-compressed": "gca",
  "model/vnd.gdl": "gdl",
  "application/vnd.google-apps.document": "gdoc",
  "text/vnd.familysearch.gedcom": "ged",
  "application/vnd.dynageo": "geo",
  "application/geo+json": "geojson",
  "application/vnd.geometry-explorer": "gex",
  "application/vnd.geogebra.file": "ggb",
  "application/vnd.geogebra.tool": "ggt",
  "application/vnd.groove-help": "ghf",
  "image/gif": "gif",
  "application/vnd.groove-identity-message": "gim",
  "model/gltf-binary": "glb",
  "model/gltf+json": "gltf",
  "application/gml+xml": "gml",
  "application/vnd.gmx": "gmx",
  "application/x-gnumeric": "gnumeric",
  "application/vnd.flographit": "gph",
  "application/gpx+xml": "gpx",
  "application/vnd.grafeq": "gqf",
  "application/srgs": "gram",
  "application/x-gramps-xml": "gramps",
  "application/vnd.groove-injector": "grv",
  "application/srgs+xml": "grxml",
  "application/x-font-ghostscript": "gsf",
  "application/vnd.google-apps.spreadsheet": "gsheet",
  "application/vnd.google-apps.presentation": "gslides",
  "application/x-gtar": "gtar",
  "application/vnd.groove-tool-message": "gtm",
  "model/vnd.gtw": "gtw",
  "text/vnd.graphviz": "gv",
  "application/gxf": "gxf",
  "application/vnd.geonext": "gxt",
  "application/gzip": "gz",
  "video/h261": "h261",
  "video/h263": "h263",
  "video/h264": "h264",
  "application/vnd.hal+xml": "hal",
  "application/vnd.hbci": "hbci",
  "text/x-handlebars-template": "hbs",
  "application/x-virtualbox-hdd": "hdd",
  "application/x-hdf": "hdf",
  "image/heic": "heic",
  "image/heic-sequence": "heics",
  "image/heif": "heif",
  "image/heif-sequence": "heifs",
  "image/hej2k": "hej2",
  "application/atsc-held+xml": "held",
  "application/hjson": "hjson",
  "application/winhlp": "hlp",
  "application/vnd.hp-hpgl": "hpgl",
  "application/vnd.hp-hpid": "hpid",
  "application/vnd.hp-hps": "hps",
  "application/mac-binhex40": "hqx",
  "image/hsj2": "hsj2",
  "application/vnd.kenameaapp": "htke",
  "text/html": "html",
  "application/vnd.yamaha.hv-dic": "hvd",
  "application/vnd.yamaha.hv-voice": "hvp",
  "application/vnd.yamaha.hv-script": "hvs",
  "application/vnd.intergeo": "i2g",
  "application/vnd.iccprofile": "icc",
  "x-conference/x-cooltalk": "ice",
  "image/x-icon": "ico",
  "text/calendar": "ics",
  "image/ief": "ief",
  "application/vnd.shana.informed.formdata": "ifm",
  "model/iges": "iges",
  "application/vnd.igloader": "igl",
  "application/vnd.insors.igm": "igm",
  "application/vnd.micrografx.igx": "igx",
  "application/vnd.shana.informed.interchange": "iif",
  "application/vnd.accpac.simply.imp": "imp",
  "application/vnd.ms-ims": "ims",
  "application/inkml+xml": "inkml",
  "application/x-install-instructions": "install",
  "application/vnd.astraea-software.iota": "iota",
  "application/ipfix": "ipfix",
  "application/vnd.shana.informed.package": "ipk",
  "application/vnd.ibm.rights-management": "irm",
  "application/vnd.irepository.package+xml": "irp",
  "application/x-iso9660-image": "iso",
  "application/vnd.shana.informed.formtemplate": "itp",
  "application/its+xml": "its",
  "application/vnd.immervision-ivp": "ivp",
  "application/vnd.immervision-ivu": "ivu",
  "text/vnd.sun.j2me.app-descriptor": "jad",
  "text/jade": "jade",
  "application/vnd.jam": "jam",
  "application/java-archive": "jar",
  "application/x-java-archive-diff": "jardiff",
  "text/x-java-source": "java",
  "image/jphc": "jhc",
  "application/vnd.jisp": "jisp",
  "image/jls": "jls",
  "application/vnd.hp-jlyt": "jlt",
  "image/x-jng": "jng",
  "application/x-java-jnlp-file": "jnlp",
  "application/vnd.joost.joda-archive": "joda",
  "image/jpeg": "jpeg",
  "image/jp2": "jpg2",
  "video/jpeg": "jpgv",
  "image/jph": "jph",
  "video/jpm": "jpm",
  "image/jpx": "jpx",
  "text/javascript": "js",
  "application/json": "json",
  "application/json5": "json5",
  "application/ld+json": "jsonld",
  "application/jsonml+json": "jsonml",
  "text/jsx": "jsx",
  "model/jt": "jt",
  "image/jxr": "jxr",
  "image/jxra": "jxra",
  "image/jxrs": "jxrs",
  "image/jxs": "jxs",
  "image/jxsc": "jxsc",
  "image/jxsi": "jxsi",
  "image/jxss": "jxss",
  "application/vnd.kde.karbon": "karbon",
  "application/x-keepass2": "kdbx",
  "application/x-iwork-keynote-sffkey": "key",
  "application/vnd.kde.kformula": "kfo",
  "application/vnd.kidspiration": "kia",
  "application/vnd.google-earth.kml+xml": "kml",
  "application/vnd.google-earth.kmz": "kmz",
  "application/vnd.kinar": "kne",
  "application/vnd.kde.kontour": "kon",
  "application/vnd.kde.kpresenter": "kpt",
  "application/vnd.ds-keypoint": "kpxx",
  "application/vnd.kde.kspread": "ksp",
  "image/ktx": "ktx",
  "image/ktx2": "ktx2",
  "application/vnd.kahootz": "ktz",
  "application/vnd.kde.kword": "kwd",
  "application/vnd.las.las+xml": "lasxml",
  "application/x-latex": "latex",
  "application/vnd.llamagraphics.life-balance.desktop": "lbd",
  "application/vnd.llamagraphics.life-balance.exchange+xml": "lbe",
  "application/vnd.hhe.lesson-player": "les",
  "text/less": "less",
  "application/lgr+xml": "lgr",
  "application/vnd.route66.link66+xml": "link66",
  "application/x-ms-shortcut": "lnk",
  "application/lost+xml": "lostxml",
  "application/vnd.ms-lrm": "lrm",
  "application/vnd.frogans.ltf": "ltf",
  "text/x-lua": "lua",
  "application/x-lua-bytecode": "luac",
  "audio/vnd.lucent.voice": "lvp",
  "application/vnd.lotus-wordpro": "lwp",
  "application/x-lzh-compressed": "lzh",
  "audio/x-mpegurl": "m3u",
  "application/vnd.apple.mpegurl": "m3u8",
  "audio/x-m4a": "m4a",
  "video/iso.segment": "m4s",
  "video/vnd.mpegurl": "m4u",
  "video/x-m4v": "m4v",
  "application/mathematica": "ma",
  "application/mads+xml": "mads",
  "application/mmt-aei+xml": "maei",
  "application/vnd.ecowin.chart": "mag",
  "text/cache-manifest": "manifest",
  "application/mathml+xml": "mathml",
  "application/vnd.mobius.mbk": "mbk",
  "application/mbox": "mbox",
  "application/vnd.medcalcdata": "mc1",
  "application/vnd.mcd": "mcd",
  "text/vnd.curl.mcurl": "mcurl",
  "text/markdown": "md",
  "application/x-msaccess": "mdb",
  "image/vnd.ms-modi": "mdi",
  "text/mdx": "mdx",
  "model/mesh": "mesh",
  "application/metalink4+xml": "meta4",
  "application/metalink+xml": "metalink",
  "application/mets+xml": "mets",
  "application/vnd.mfmp": "mfm",
  "application/rpki-manifest": "mft",
  "application/vnd.osgeo.mapguide.package": "mgp",
  "application/vnd.proteus.magazine": "mgz",
  "audio/midi": "midi",
  "application/x-mie": "mie",
  "application/vnd.mif": "mif",
  "message/rfc822": "mime",
  "video/mj2": "mj2",
  "audio/x-matroska": "mka",
  "text/x-markdown": "mkd",
  "video/x-matroska": "mks",
  "application/vnd.dolby.mlp": "mlp",
  "application/vnd.chipnuts.karaoke-mmd": "mmd",
  "application/vnd.smaf": "mmf",
  "text/mathml": "mml",
  "image/vnd.fujixerox.edmics-mmr": "mmr",
  "video/x-mng": "mng",
  "application/x-msmoney": "mny",
  "application/x-mobipocket-ebook": "mobi",
  "application/mods+xml": "mods",
  "video/x-sgi-movie": "movie",
  "application/mp21": "mp21",
  "audio/mpeg": "mp3",
  "video/mp4": "mp4",
  "audio/mp4": "mp4a",
  "application/mp4": "mp4s",
  "application/vnd.mophun.certificate": "mpc",
  "application/dash+xml": "mpd",
  "video/mpeg": "mpeg",
  "application/media-policy-dataset+xml": "mpf",
  "application/vnd.apple.installer+xml": "mpkg",
  "application/vnd.blueice.multipass": "mpm",
  "application/vnd.mophun.application": "mpn",
  "application/vnd.ms-project": "mpp",
  "application/vnd.ibm.minipay": "mpy",
  "application/vnd.mobius.mqy": "mqy",
  "application/marc": "mrc",
  "application/marcxml+xml": "mrcx",
  "application/mediaservercontrol+xml": "mscml",
  "application/vnd.fdsn.mseed": "mseed",
  "application/vnd.mseq": "mseq",
  "application/vnd.epson.msf": "msf",
  "application/vnd.ms-outlook": "msg",
  "application/msix": "msix",
  "application/msixbundle": "msixbundle",
  "application/vnd.mobius.msl": "msl",
  "application/vnd.muvee.style": "msty",
  "model/mtl": "mtl",
  "model/vnd.mts": "mts",
  "application/vnd.musician": "mus",
  "application/mmt-usd+xml": "musd",
  "application/vnd.recordare.musicxml+xml": "musicxml",
  "application/x-msmediaview": "mvb",
  "application/vnd.mapbox-vector-tile": "mvt",
  "application/vnd.mfer": "mwf",
  "application/mxf": "mxf",
  "application/vnd.recordare.musicxml": "mxl",
  "audio/mobile-xmf": "mxmf",
  "application/vnd.triscape.mxs": "mxs",
  "application/vnd.nokia.n-gage.symbian.install": "n-gage",
  "text/n3": "n3",
  "application/vnd.wolfram.player": "nbp",
  "application/x-dtbncx+xml": "ncx",
  "text/x-nfo": "nfo",
  "application/vnd.nokia.n-gage.data": "ngdat",
  "application/vnd.nitf": "nitf",
  "application/vnd.neurolanguage.nlu": "nlu",
  "application/vnd.enliven": "nml",
  "application/vnd.noblenet-directory": "nnd",
  "application/vnd.noblenet-sealer": "nns",
  "application/vnd.noblenet-web": "nnw",
  "image/vnd.net-fpx": "npx",
  "application/n-quads": "nq",
  "application/x-conference": "nsc",
  "application/vnd.lotus-notes": "nsf",
  "application/n-triples": "nt",
  "application/x-iwork-numbers-sffnumbers": "numbers",
  "application/x-nzb": "nzb",
  "application/vnd.fujitsu.oasys2": "oa2",
  "application/vnd.fujitsu.oasys3": "oa3",
  "application/vnd.fujitsu.oasys": "oas",
  "application/x-msbinder": "obd",
  "application/vnd.openblox.game+xml": "obgx",
  "model/obj": "obj",
  "application/oda": "oda",
  "application/vnd.oasis.opendocument.database": "odb",
  "application/vnd.oasis.opendocument.chart": "odc",
  "application/vnd.oasis.opendocument.formula": "odf",
  "application/vnd.oasis.opendocument.formula-template": "odft",
  "application/vnd.oasis.opendocument.graphics": "odg",
  "application/vnd.oasis.opendocument.image": "odi",
  "application/vnd.oasis.opendocument.text-master": "odm",
  "application/vnd.oasis.opendocument.presentation": "odp",
  "application/vnd.oasis.opendocument.spreadsheet": "ods",
  "application/vnd.oasis.opendocument.text": "odt",
  "model/vnd.opengex": "ogex",
  "audio/ogg": "ogg",
  "video/ogg": "ogv",
  "application/ogg": "ogx",
  "application/omdoc+xml": "omdoc",
  "application/onenote": "onetoc",
  "application/oebps-package+xml": "opf",
  "text/x-opml": "opml",
  "application/vnd.palm": "oprc",
  "text/x-org": "org",
  "application/vnd.yamaha.openscoreformat": "osf",
  "application/vnd.yamaha.openscoreformat.osfpvg+xml": "osfpvg",
  "application/vnd.openstreetmap.data+xml": "osm",
  "application/vnd.oasis.opendocument.chart-template": "otc",
  "font/otf": "otf",
  "application/vnd.oasis.opendocument.graphics-template": "otg",
  "application/vnd.oasis.opendocument.text-web": "oth",
  "application/vnd.oasis.opendocument.image-template": "oti",
  "application/vnd.oasis.opendocument.presentation-template": "otp",
  "application/vnd.oasis.opendocument.spreadsheet-template": "ots",
  "application/vnd.oasis.opendocument.text-template": "ott",
  "application/x-virtualbox-ova": "ova",
  "application/x-virtualbox-ovf": "ovf",
  "application/oxps": "oxps",
  "application/vnd.openofficeorg.extension": "oxt",
  "application/pkcs10": "p10",
  "application/x-pkcs12": "p12",
  "application/x-pkcs7-certificates": "p7b",
  "application/pkcs7-mime": "p7c",
  "application/x-pkcs7-certreqresp": "p7r",
  "application/pkcs7-signature": "p7s",
  "application/pkcs8": "p8",
  "application/x-ns-proxy-autoconfig": "pac",
  "application/x-iwork-pages-sffpages": "pages",
  "text/x-pascal": "pas",
  "application/vnd.pawaafile": "paw",
  "application/vnd.powerbuilder6": "pbd",
  "image/x-portable-bitmap": "pbm",
  "application/x-font-pcf": "pcf",
  "application/vnd.hp-pcl": "pcl",
  "application/vnd.hp-pclxl": "pclxl",
  "application/vnd.curl.pcurl": "pcurl",
  "image/x-pcx": "pcx",
  "application/x-pilot": "pdb",
  "text/x-processing": "pde",
  "application/pdf": "pdf",
  "application/x-font-type1": "pfa",
  "application/font-tdpfr": "pfr",
  "image/x-portable-graymap": "pgm",
  "application/x-chess-pgn": "pgn",
  "application/pgp-encrypted": "pgp",
  "application/x-httpd-php": "php",
  "image/x-pict": "pic",
  "application/pkixcmp": "pki",
  "application/pkix-pkipath": "pkipath",
  "application/vnd.apple.pkpass": "pkpass",
  "application/x-perl": "pl",
  "application/vnd.3gpp.pic-bw-large": "plb",
  "application/vnd.mobius.plc": "plc",
  "application/vnd.pocketlearn": "plf",
  "application/pls+xml": "pls",
  "application/vnd.ctc-posml": "pml",
  "image/png": "png",
  "image/x-portable-anymap": "pnm",
  "application/vnd.macports.portpkg": "portpkg",
  "application/vnd.ms-powerpoint.template.macroenabled.12": "potm",
  "application/vnd.openxmlformats-officedocument.presentationml.template":
    "potx",
  "application/vnd.ms-powerpoint.addin.macroenabled.12": "ppam",
  "application/vnd.cups-ppd": "ppd",
  "image/x-portable-pixmap": "ppm",
  "application/vnd.ms-powerpoint.slideshow.macroenabled.12": "ppsm",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
    "ppsx",
  "application/vnd.ms-powerpoint": "ppt",
  "application/vnd.ms-powerpoint.presentation.macroenabled.12": "pptm",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "pptx",
  "model/prc": "prc",
  "application/vnd.lotus-freelance": "pre",
  "application/pics-rules": "prf",
  "application/provenance+xml": "provx",
  "application/postscript": "ps",
  "application/vnd.3gpp.pic-bw-small": "psb",
  "image/vnd.adobe.photoshop": "psd",
  "application/x-font-linux-psf": "psf",
  "application/pskc+xml": "pskcxml",
  "image/prs.pti": "pti",
  "application/vnd.pvi.ptid1": "ptid",
  "application/x-mspublisher": "pub",
  "application/vnd.3gpp.pic-bw-var": "pvb",
  "application/vnd.3m.post-it-notes": "pwn",
  "audio/vnd.ms-playready.media.pya": "pya",
  "model/vnd.pytha.pyox": "pyox",
  "video/vnd.ms-playready.media.pyv": "pyv",
  "application/vnd.epson.quickanime": "qam",
  "application/vnd.intu.qbo": "qbo",
  "application/vnd.intu.qfx": "qfx",
  "application/vnd.publishare-delta-tree": "qps",
  "video/quicktime": "qt",
  "application/vnd.quark.quarkxpress": "qxt",
  "audio/x-realaudio": "ra",
  "audio/x-pn-realaudio": "ram",
  "application/raml+yaml": "raml",
  "application/route-apd+xml": "rapd",
  "application/x-rar-compressed": "rar",
  "image/x-cmu-raster": "ras",
  "application/vnd.ipunplugged.rcprofile": "rcprofile",
  "application/rdf+xml": "rdf",
  "application/vnd.data-vision.rdz": "rdz",
  "application/p2p-overlay+xml": "relo",
  "application/vnd.businessobjects": "rep",
  "application/x-dtbresource+xml": "res",
  "image/x-rgb": "rgb",
  "application/reginfo+xml": "rif",
  "audio/vnd.rip": "rip",
  "application/x-research-info-systems": "ris",
  "application/resource-lists+xml": "rl",
  "image/vnd.fujixerox.edmics-rlc": "rlc",
  "application/resource-lists-diff+xml": "rld",
  "application/vnd.rn-realmedia": "rm",
  "audio/x-pn-realaudio-plugin": "rmp",
  "application/vnd.jcp.javame.midlet-rms": "rms",
  "application/vnd.rn-realmedia-vbr": "rmvb",
  "application/relax-ng-compact-syntax": "rnc",
  "application/rpki-roa": "roa",
  "text/troff": "roff",
  "application/vnd.cloanto.rp9": "rp9",
  "application/x-redhat-package-manager": "rpm",
  "application/vnd.nokia.radio-presets": "rpss",
  "application/vnd.nokia.radio-preset": "rpst",
  "application/sparql-query": "rq",
  "application/rls-services+xml": "rs",
  "application/atsc-rsat+xml": "rsat",
  "application/rsd+xml": "rsd",
  "application/urc-ressheet+xml": "rsheet",
  "application/rss+xml": "rss",
  "text/rtf": "rtf",
  "text/richtext": "rtx",
  "application/x-makeself": "run",
  "application/route-usd+xml": "rusd",
  "audio/s3m": "s3m",
  "application/vnd.yamaha.smaf-audio": "saf",
  "text/x-sass": "sass",
  "application/sbml+xml": "sbml",
  "application/vnd.ibm.secure-container": "sc",
  "application/x-msschedule": "scd",
  "application/vnd.lotus-screencam": "scm",
  "application/scvp-cv-request": "scq",
  "application/scvp-cv-response": "scs",
  "text/x-scss": "scss",
  "text/vnd.curl.scurl": "scurl",
  "application/vnd.stardivision.draw": "sda",
  "application/vnd.stardivision.calc": "sdc",
  "application/vnd.stardivision.impress": "sdd",
  "application/vnd.solent.sdkm+xml": "sdkm",
  "application/sdp": "sdp",
  "application/x-sea": "sea",
  "application/vnd.seemail": "see",
  "application/vnd.fdsn.seed": "seed",
  "application/vnd.sema": "sema",
  "application/vnd.semd": "semd",
  "application/vnd.semf": "semf",
  "application/senml+xml": "senmlx",
  "application/sensml+xml": "sensmlx",
  "application/java-serialized-object": "ser",
  "application/set-payment-initiation": "setpay",
  "application/set-registration-initiation": "setreg",
  "application/vnd.hydrostatix.sof-data": "sfd-hdstx",
  "application/vnd.spotfire.sfs": "sfs",
  "text/x-sfv": "sfv",
  "image/sgi": "sgi",
  "application/vnd.stardivision.writer-global": "sgl",
  "text/sgml": "sgml",
  "application/x-sh": "sh",
  "application/x-shar": "shar",
  "text/shex": "shex",
  "application/shf+xml": "shf",
  "image/x-mrsid-image": "sid",
  "application/sieve": "sieve",
  "application/pgp-signature": "sig",
  "audio/silk": "sil",
  "application/vnd.symbian.install": "sis",
  "application/x-stuffit": "sit",
  "application/x-stuffitx": "sitx",
  "application/vnd.koan": "skt",
  "application/vnd.ms-powerpoint.slide.macroenabled.12": "sldm",
  "application/vnd.openxmlformats-officedocument.presentationml.slide": "sldx",
  "text/slim": "slim",
  "application/route-s-tsid+xml": "sls",
  "application/vnd.epson.salt": "slt",
  "application/vnd.stepmania.stepchart": "sm",
  "application/vnd.stardivision.math": "smf",
  "application/smil+xml": "smil",
  "video/x-smv": "smv",
  "application/vnd.stepmania.package": "smzip",
  "application/x-font-snf": "snf",
  "text/spdx": "spdx",
  "application/vnd.yamaha.smaf-phrase": "spf",
  "application/x-futuresplash": "spl",
  "text/vnd.in3d.spot": "spot",
  "application/scvp-vp-response": "spp",
  "application/scvp-vp-request": "spq",
  "application/x-sql": "sql",
  "application/x-wais-source": "src",
  "application/x-subrip": "srt",
  "application/sru+xml": "sru",
  "application/sparql-results+xml": "srx",
  "application/ssdl+xml": "ssdl",
  "application/vnd.kodak-descriptor": "sse",
  "application/vnd.epson.ssf": "ssf",
  "application/ssml+xml": "ssml",
  "application/vnd.sailingtracker.track": "st",
  "application/vnd.sun.xml.calc.template": "stc",
  "application/vnd.sun.xml.draw.template": "std",
  "application/vnd.wt.stf": "stf",
  "application/vnd.sun.xml.impress.template": "sti",
  "application/hyperstudio": "stk",
  "model/stl": "stl",
  "model/step+xml": "stpx",
  "model/step-xml+zip": "stpxz",
  "model/step+zip": "stpz",
  "application/vnd.pg.format": "str",
  "application/vnd.sun.xml.writer.template": "stw",
  "text/stylus": "stylus",
  "text/vnd.dvb.subtitle": "sub",
  "application/vnd.sus-calendar": "sus",
  "application/x-sv4cpio": "sv4cpio",
  "application/x-sv4crc": "sv4crc",
  "application/vnd.dvb.service": "svc",
  "application/vnd.svd": "svd",
  "image/svg+xml": "svg",
  "application/x-shockwave-flash": "swf",
  "application/vnd.aristanetworks.swi": "swi",
  "application/swid+xml": "swidtag",
  "application/vnd.sun.xml.calc": "sxc",
  "application/vnd.sun.xml.draw": "sxd",
  "application/vnd.sun.xml.writer.global": "sxg",
  "application/vnd.sun.xml.impress": "sxi",
  "application/vnd.sun.xml.math": "sxm",
  "application/vnd.sun.xml.writer": "sxw",
  "application/x-t3vm-image": "t3",
  "image/t38": "t38",
  "application/vnd.mynfc": "taglet",
  "application/vnd.tao.intent-module-archive": "tao",
  "image/vnd.tencent.tap": "tap",
  "application/x-tar": "tar",
  "application/vnd.3gpp2.tcap": "tcap",
  "application/x-tcl": "tcl",
  "application/urc-targetdesc+xml": "td",
  "application/vnd.smart.teacher": "teacher",
  "application/tei+xml": "tei",
  "application/x-tex": "tex",
  "application/x-texinfo": "texinfo",
  "text/plain": "text",
  "application/thraud+xml": "tfi",
  "application/x-tex-tfm": "tfm",
  "image/tiff-fx": "tfx",
  "image/x-tga": "tga",
  "application/vnd.ms-officetheme": "thmx",
  "image/tiff": "tiff",
  "application/vnd.tmobile-livetv": "tmo",
  "application/toml": "toml",
  "application/x-bittorrent": "torrent",
  "application/vnd.groove-tool-template": "tpl",
  "application/vnd.trid.tpt": "tpt",
  "application/vnd.trueapp": "tra",
  "application/trig": "trig",
  "application/x-msterminal": "trm",
  "video/mp2t": "ts",
  "application/timestamped-data": "tsd",
  "text/tab-separated-values": "tsv",
  "font/collection": "ttc",
  "font/ttf": "ttf",
  "text/turtle": "ttl",
  "application/ttml+xml": "ttml",
  "application/vnd.simtech-mindmapper": "twd",
  "application/vnd.genomatix.tuxedo": "txd",
  "application/vnd.mobius.txf": "txf",
  "model/u3d": "u3d",
  "message/global-delivery-status": "u8dsn",
  "message/global-headers": "u8hdr",
  "message/global-disposition-notification": "u8mdn",
  "message/global": "u8msg",
  "application/ubjson": "ubj",
  "application/vnd.ufdl": "ufdl",
  "application/x-glulx": "ulx",
  "application/vnd.umajin": "umj",
  "application/vnd.unity": "unityweb",
  "application/vnd.uoml+xml": "uoml",
  "text/uri-list": "urls",
  "model/vnd.usda": "usda",
  "model/vnd.usdz+zip": "usdz",
  "application/x-ustar": "ustar",
  "application/vnd.uiq.theme": "utz",
  "text/x-uuencode": "uu",
  "audio/vnd.dece.audio": "uva",
  "application/vnd.dece.data": "uvd",
  "image/vnd.dece.graphic": "uvg",
  "video/vnd.dece.hd": "uvh",
  "video/vnd.dece.mobile": "uvm",
  "video/vnd.dece.pd": "uvp",
  "video/vnd.dece.sd": "uvs",
  "application/vnd.dece.ttml+xml": "uvt",
  "video/vnd.uvvu.mp4": "uvu",
  "video/vnd.dece.video": "uvv",
  "application/vnd.dece.unspecified": "uvx",
  "application/vnd.dece.zip": "uvz",
  "application/x-virtualbox-vbox": "vbox",
  "application/x-virtualbox-vbox-extpack": "vbox-extpack",
  "text/vcard": "vcard",
  "application/x-cdlink": "vcd",
  "text/x-vcard": "vcf",
  "application/vnd.groove-vcard": "vcg",
  "text/x-vcalendar": "vcs",
  "application/vnd.vcx": "vcx",
  "application/x-virtualbox-vdi": "vdi",
  "model/vnd.sap.vds": "vds",
  "application/x-virtualbox-vhd": "vhd",
  "application/vnd.visionary": "vis",
  "video/vnd.vivo": "viv",
  "application/x-virtualbox-vmdk": "vmdk",
  "video/x-ms-vob": "vob",
  "application/vnd.stardivision.writer": "vor",
  "model/vrml": "vrml",
  "application/vnd.visio": "vsd",
  "application/vnd.vsf": "vsf",
  "image/vnd.valve.source.texture": "vtf",
  "text/vtt": "vtt",
  "model/vnd.vtu": "vtu",
  "application/voicexml+xml": "vxml",
  "application/x-doom": "wad",
  "application/vnd.sun.wadl+xml": "wadl",
  "application/wasm": "wasm",
  "audio/x-wav": "wav",
  "audio/x-ms-wax": "wax",
  "image/vnd.wap.wbmp": "wbmp",
  "application/vnd.criticaltools.wbs+xml": "wbs",
  "application/vnd.wap.wbxml": "wbxml",
  "application/vnd.ms-works": "wcm",
  "image/vnd.ms-photo": "wdp",
  "audio/webm": "weba",
  "application/x-web-app-manifest+json": "webapp",
  "video/webm": "webm",
  "application/manifest+json": "webmanifest",
  "image/webp": "webp",
  "application/vnd.pmi.widget": "wg",
  "text/wgsl": "wgsl",
  "application/widget": "wgt",
  "application/watcherinfo+xml": "wif",
  "video/x-ms-wm": "wm",
  "audio/x-ms-wma": "wma",
  "application/x-ms-wmd": "wmd",
  "image/wmf": "wmf",
  "text/vnd.wap.wml": "wml",
  "application/vnd.wap.wmlc": "wmlc",
  "text/vnd.wap.wmlscript": "wmls",
  "application/vnd.wap.wmlscriptc": "wmlsc",
  "video/x-ms-wmv": "wmv",
  "video/x-ms-wmx": "wmx",
  "font/woff": "woff",
  "font/woff2": "woff2",
  "application/vnd.wordperfect": "wpd",
  "application/vnd.ms-wpl": "wpl",
  "application/vnd.wqd": "wqd",
  "application/x-mswrite": "wri",
  "message/vnd.wfa.wsc": "wsc",
  "application/wsdl+xml": "wsdl",
  "application/wspolicy+xml": "wspolicy",
  "application/vnd.webturbo": "wtb",
  "video/x-ms-wvx": "wvx",
  "model/x3d+xml": "x3d",
  "model/x3d+fastinfoset": "x3db",
  "model/x3d+binary": "x3dbz",
  "model/x3d-vrml": "x3dv",
  "model/x3d+vrml": "x3dvz",
  "model/vnd.parasolid.transmit.binary": "x_b",
  "model/vnd.parasolid.transmit.text": "x_t",
  "application/xaml+xml": "xaml",
  "application/x-silverlight-app": "xap",
  "application/vnd.xara": "xar",
  "application/xcap-att+xml": "xav",
  "application/x-ms-xbap": "xbap",
  "application/vnd.fujixerox.docuworks.binder": "xbd",
  "image/x-xbitmap": "xbm",
  "application/xcap-caps+xml": "xca",
  "application/calendar+xml": "xcs",
  "application/xcap-diff+xml": "xdf",
  "application/vnd.syncml.dm+xml": "xdm",
  "application/vnd.adobe.xdp+xml": "xdp",
  "application/dssc+xml": "xdssc",
  "application/vnd.fujixerox.docuworks": "xdw",
  "application/xcap-el+xml": "xel",
  "application/xenc+xml": "xenc",
  "application/patch-ops-error+xml": "xer",
  "application/xfdf": "xfdf",
  "application/vnd.xfdl": "xfdl",
  "application/vnd.pwg-xhtml-print+xml": "xhtm",
  "application/xhtml+xml": "xhtml",
  "image/vnd.xiff": "xif",
  "application/xliff+xml": "xlf",
  "application/vnd.ms-excel.sheet.binary.macroenabled.12": "xlsb",
  "application/vnd.ms-excel.sheet.macroenabled.12": "xlsm",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.ms-excel": "xlt",
  "application/vnd.ms-excel.template.macroenabled.12": "xltm",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
    "xltx",
  "audio/xm": "xm",
  "text/xml": "xml",
  "application/xcap-ns+xml": "xns",
  "application/vnd.olpc-sugar": "xo",
  "application/xop+xml": "xop",
  "application/x-xpinstall": "xpi",
  "application/xproc+xml": "xpl",
  "image/x-xpixmap": "xpm",
  "application/vnd.is-xpr": "xpr",
  "application/vnd.ms-xpsdocument": "xps",
  "application/vnd.intercon.formnet": "xpx",
  "application/prs.xsf+xml": "xsf",
  "application/xslt+xml": "xslt",
  "application/vnd.syncml+xml": "xsm",
  "application/xspf+xml": "xspf",
  "application/vnd.mozilla.xul+xml": "xul",
  "application/xv+xml": "xvml",
  "image/x-xwindowdump": "xwd",
  "chemical/x-xyz": "xyz",
  "application/x-xz": "xz",
  "text/yaml": "yaml",
  "application/yang": "yang",
  "application/yin+xml": "yin",
  "text/x-suse-ymp": "ymp",
  "application/x-zmachine": "z1",
  "application/vnd.zzazz.deck+xml": "zaz",
  "application/zip": "zip",
  "application/vnd.zul": "zir",
  "application/vnd.handheld-entertainment+xml": "zmm",
} as const;
/** cSpell: enable */

type MIME_TYPES = keyof typeof MIME_TO_HUMAN;

export const humanFileType = (filetype: string) => {
  return MIME_TO_HUMAN[filetype as MIME_TYPES]?.toUpperCase() || filetype;
};
