import observeUnread from "./observers/unread";

// Mark message as read
const markAsRead = (message: HTMLElement) => {
  if (!message) return;

  message.classList.add("read");
  message.classList.remove("unread");

  const sentMessageReadMark = message.querySelector(
    ".sent-message .status-container .sent-read-mark",
  );

  if (sentMessageReadMark) {
    sentMessageReadMark.classList.replace("bi-check2", "bi-check2-all");
  }

  observeUnread();
};

export default markAsRead;
